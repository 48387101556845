import Footer from '@/components/layout/footer';
import Header from '@/components/layout/header';
import InvoiceContextProvider from '@/contexts/invoice-context';
import { Outlet } from "react-router-dom";


function AuthenticatedAppRoot() {
    return (
        <InvoiceContextProvider>
            <header>
                <Header />
            </header>
            <article>
                <Outlet />
            </article>
            <footer>
                <Footer />
            </footer>
        </InvoiceContextProvider>
    );
}

export default AuthenticatedAppRoot;