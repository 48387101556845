import { FirmContext } from "@/contexts/firm-context-provider";
import { useContext } from "react";
import { Container, Row } from "react-bootstrap";

import './footer.scss';

function Footer() {
    const firmContext = useContext(FirmContext);
    return (
        <Container className="f-footer" fluid>
            <Row className='bg-primary text-white'>
                This payment portal is managed by {firmContext.firm.MerchantName} and powered by Factor A/E - if you have questions regarding your invoicing or payment status, contact the vendor who sent you a link to this payment portal.
            </Row>
            <Row className='bg-primary text-white'>
                For questions regarding Factor, contact Factor at <a href="https://www.factorapp.com" target="_blank">factorapp.com</a>
            </Row>
        </Container>
    )
}

export default Footer;